import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageTitle} || Minh Nguyen Developer's Home </title>
                    <meta name="description" content="I'm Minh, a Senior Backend Developer with more than 5 years in PHP and Wordpress development." />
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
