import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component{
    render(){
        let 
        tab1 = "Main skills",
        tab3 = "Experience",
        tab4 = "Education & Certification";
        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab3}</Tab>
                                        <Tab>{tab4}</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <a href="/service">Wordpress Developer <span> - Senior</span></a>
                                                    Wordpress themes, Wordpress plugins, WooCommerce, third-party integration...
                                                </li>
                                                <li>
                                                    <a href="/service">PHP Developer<span> - Senior</span></a>
                                                    OOP, MVC, basic front-end technologies (HTML5, JS/jQuery, CSS3...), NodeJS, AWS...
                                                </li>
                                                <li>
                                                    <a href="/service">Programming tools</a>
                                                    PHPStorm, Git/Git flow, Gulp, Docker, Composer, NPM,...
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">PHP Developer<span> - Mobiloud</span></a> 2020 - Current
                                               </li>
                                               <li>
                                                   <a href="/service">Backend Developer<span> - Sunbytes</span></a> 2018 - Current
                                               </li>
                                               <li>
                                                   <a href="/service">Wordpress Developer<span> - 9thWonder</span></a> 2014 - 2018
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">The Degree of Engineer Information Technology<span> - Nha Trang University</span></a> 2010
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;