import React ,{ Component }from "react";
import { FiServer , FiLayers , FiEdit2 , FiShoppingCart, FiPackage, FiShield } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiPackage />,
        title: 'Wordpress Theme Development',
        description: 'I can create themes for your website with a unique look. My themes can also be optimized for your mobile users with a smooth performance. I also can integrate any kind of theme that you have purchased with your website.'
    },
    {
        icon: <FiLayers />,
        title: 'WordPress Plugin Development',
        description: 'I can provide you with good customized WordPress plugin development solutions that can work perfectly for your business requirements, follow the Wordpress best coding practices'
    },
    {
        icon: <FiShoppingCart />,
        title: 'WordPress Ecommerce Services',
        description: 'I can create a customized online shopping portal for you that can allow you to reach out to your customers easily using WooCommerce. I also can customize your shopping portal and promote your products in the most effective manner.'
    },
    { 
        icon: <FiShield />,
        title: 'WordPress Security',
        description: 'Ensuring the safety and security of your website should always be a top priority. I can help you to make your website become safer and more security'
    },
    {
        icon: <FiServer />,
        title: 'API Integration & Customization',
        description: 'Extending your WordPress website capabilities, you can opt for API integration services. I ensure a seamless data sync and offer mobile app integration services with your site.'
    },
    { 
        icon: <FiEdit2 />,
        title: 'Blog Development',
        description: 'I will help you develop purely scalable and engaging WordPress blog themes at the best market prices and within the shortest time.'
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a href="javascript:void(0);">
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
